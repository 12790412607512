import { render, staticRenderFns } from "./AddWhiteLabel.vue?vue&type=template&id=28a1528d&scoped=true&"
import script from "./AddWhiteLabel.vue?vue&type=script&lang=js&"
export * from "./AddWhiteLabel.vue?vue&type=script&lang=js&"
import style0 from "./AddWhiteLabel.vue?vue&type=style&index=0&id=28a1528d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28a1528d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCheckbox,VCol,VContainer,VFileInput,VForm,VLabel,VRow,VSnackbar,VTextField})
